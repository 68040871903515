const config = {
  "broker": "3",
  "name": "German",
  "registrationBundle": "german",
  "defaultCountryName": "Germany",
  "defaultCountryCode": "DE",
  "fxgeoip_code": "DE",
  languageCode: 'de',
  languageName:'german',
  spokenLanguageCode:'de',
  direction:'ltr',
  footerDisclaimer:'900',
  brand: 'iforex'
};
module.exports = config;